import { Controller, useForm } from "react-hook-form";
import { PhoneNumber } from "./Form";
import { Button, TextField } from "@mui/material";

import React, { useState, useEffect } from "react";

import PhoneOrMessagingServiceDropdownComponent from "./PhoneOrMessagingServiceDropdown";
import FileDropzone from "../FileDropzone";
import ImagePreview from "./ImagePreview";

const SendTestMessageForm = (props) => {
  const {
    control,
    handleSubmit,
    messageDetails,
    setMessageDetails,
    handleSendBlast,
  } = props;

  const [loading, setLoading] = useState(false);

  const handleFileUpload = (files) => {
    setLoading(true);
    console.log("Received files:", files);

    const file = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      setMessageDetails((prev) => ({
        ...prev,
        base64MessageImage: base64String,
        imageFile: file,
      }));
    };

    reader.onerror = (error) => {
      console.log("Error reading file:", error);
      setLoading(false);
    };
  };

  const removeImage = () => {
    setMessageDetails((prev) => ({
      ...prev,
      base64MessageImage: null,
      imageFile: null,
    }));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        width: "350px",
      }}
    >
      <PhoneNumber
        name="To"
        control={control}
        phone={messageDetails?.toNumber}
        setPhone={(phone) => {
          setMessageDetails({ ...messageDetails, toNumber: phone });
        }}
      />
      <PhoneOrMessagingServiceDropdownComponent
        selectedNumber={messageDetails?.fromNumber}
        selectedService={messageDetails?.messagingServiceSID}
        setSelectedNumber={(num) => {
          setMessageDetails({ ...messageDetails, fromNumber: num });
        }}
        setSelectedService={(serv) => {
          setMessageDetails({ ...messageDetails, messagingServiceSID: serv });
        }}
      />

      <FileDropzone
        onFileUpload={handleFileUpload}
        selectedFile={messageDetails.imageFile}
        acceptedFileTypes={{
          "image/jpeg": [".jpg", ".jpeg"],
          "image/png": [".png"],
        }}
        dropzoneMessage="Drag and drop an image file here, or click to select files"
      />

      <ImagePreview
        imageFile={
          messageDetails?.imageFile
            ? URL.createObjectURL(messageDetails?.imageFile)
            : null
        }
        onRemove={removeImage}
      />

      <Controller
        control={control}
        name={"messageBody"}
        defaultValue=""
        rules={{
          required: {
            value: true,
            message: "You must enter a message body",
          },
        }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <TextField
            id="filled-multiline-static"
            label="Body"
            multiline
            fullWidth
            rows={4}
            required={true}
            value={messageDetails?.messageBody}
            variant="filled"
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              const curr = e.target.value;
              onChange(curr);
              setMessageDetails({ ...messageDetails, messageBody: curr });
            }}
            error={error !== undefined}
            helperText={error ? error.message : ""}
          />
        )}
      />

      <Button variant="outlined" onClick={handleSubmit(handleSendBlast)}>
        Send
      </Button>
    </div>
  );
};

export default SendTestMessageForm;
