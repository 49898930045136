import React, { useCallback, useState } from "react";
import cellPhoneExterior from "../images/cell-phone-exterior.png";
import useIsMobile from "../hooks/useIsMobile";

const MessagePreview = (props) => {
  const { imageFile, message } = props;
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        width: isMobile ? "100%" : "400px",
        height: isMobile ? "auto" : "800px",
        display: "flex",
        flexDirection: "column",

        padding: "2em",
        alignItems: "flex-end",
        rowGap: "10px",
        backgroundImage: `url(${cellPhoneExterior})`, // Set the background image
        backgroundSize: "cover", // Cover the entire div area
        backgroundPosition: "center", // Center the background image
        paddingTop: "10em",
      }}
    >
      <div
        style={{ position: "relative", display: "inline-block", width: "75%" }}
      >
        {imageFile && (
          <img
            src={
              typeof imageFile === "string"
                ? imageFile
                : URL.createObjectURL(imageFile)
            }
            alt="Preview"
            style={{ width: "100%", height: "auto", borderRadius: "15px" }}
          />
        )}
      </div>

      {message && (
        <div
          style={{
            backgroundColor: "#eeeeee",
            borderRadius: "15px",
            padding: "10px",
            whiteSpace: "pre-wrap",
            width: "75%",
          }}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default MessagePreview;
