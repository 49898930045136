import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
  InputAdornment,
  Paper,
  Box,
  Typography,
} from "@mui/material";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import EventIcon from "@mui/icons-material/Event";

import moment from "moment";

const PromoCodeForm = (props) => {
  const role = sessionStorage.getItem("role");
  const { campaign, setCampaign } = props;
  const [addPromo, setAddPromo] = useState(false);
  const [isExpirationEnabled, setIsExpirationEnabled] = useState(false);
  const MAX_CUSTOM_OFFER_LENGTH = 50;

  useEffect(() => {
    if (campaign?.promotion && Object.keys(campaign.promotion).length > 0) {
      setAddPromo(true);

      if (campaign.promotion.expiration) {
        // Logic to handle when there is an expiration date
        setIsExpirationEnabled(true);
      }
    }
  }, [campaign]); // Add `campaign` to the dependency array

  const handleSetPromoExpiration = (expiration) => {
    setCampaign({
      ...campaign,
      promotion: { ...campaign?.promotion, expiration: expiration },
    });
  };

  const handleSetPromoCode = (code) => {
    setCampaign({
      ...campaign,
      promoCode: code,
      promotion: { ...campaign?.code, code: code },
    });
  };

  const handleExpirationToggle = (event) => {
    setIsExpirationEnabled(event.target.checked);
    if (!event.target.checked) {
      handleSetPromoExpiration(null); // Clear expiration date when toggle is off
    } else {
      handleSetPromoExpiration(moment());
    }
  };

  const handlePromoTypeChange = (event) => {
    console.log("promo type changed");
    setCampaign({
      ...campaign,
      promotion: {
        ...campaign?.promotion,
        promoType: event.target.value,
        discount: "",
      },
    });
  };

  const handleDiscountChange = (event) => {
    const value = event.target.value;
    // Allow only valid numeric inputs (including decimal points)
    if (value === "" || /^-?\d*\.?\d{0,2}$/.test(value)) {
      handleSetDiscountValue(value);
    }
    console.log("Change - Value:", value); // Log for debugging
  };

  const handleDiscountBlur = () => {
    let discountValue = campaign?.promotion?.discount || "";

    if (discountValue && !isNaN(discountValue)) {
      // Ensure it's a number and format it
      let formattedValue = parseFloat(discountValue).toFixed(2);
      if (campaign?.promotion?.promoType === "percent") {
        formattedValue = Math.max(
          0,
          Math.min(100, parseFloat(formattedValue))
        ).toFixed(2);
      } else {
        formattedValue = Math.max(0, parseFloat(formattedValue)).toFixed(2);
      }
      handleSetDiscountValue(formattedValue);
    }
    console.log("Blur - Value:", discountValue); // Log for debugging
  };

  const handleSetDiscountValue = (discount) => {
    setCampaign({
      ...campaign,
      promotion: { ...campaign?.promotion, discount: discount },
    });

    console.log("Set - Value:", discount); // Log for debugging
  };

  const handleCustomOfferChange = (e) => {
    if (e.target.value.length <= MAX_CUSTOM_OFFER_LENGTH) {
      setCampaign({
        ...campaign,
        promotion: { ...campaign?.promotion, discount: e.target.value },
      });
    }
  };

  const handleAddPromo = () => {
    const newVal = !addPromo;

    setAddPromo(newVal);

    if (newVal) {
      setCampaign({
        ...campaign,
        promotion: {
          code: "EXAMPLECODE",
          expiration: null,
          promoType: "dollar",
          discount: "10.00",
        },
      });
    } else {
      setCampaign({
        ...campaign,
        promotion: null,
      });
    }
  };

  return (
    <div>
      <Paper
        elevation={2}
        style={{
          padding: "20px",
          borderRadius: "10px",
          width: "300px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "2em",
        }}
      >
        <Box display="flex" alignItems="center" gap="15px">
          <LocalOfferIcon color="action" />
          <Typography variant="subtitle1" component="div">
            Include Promotion
          </Typography>
          <FormControlLabel
            control={
              <Switch
                disabled={role != "admin"}
                checked={addPromo}
                onChange={() => handleAddPromo()}
              />
            }
            label=""
          />
        </Box>
      </Paper>

      {addPromo && (
        <>
          <TextField
            disabled={role != "admin"}
            label="Code"
            variant="outlined"
            fullWidth
            margin="normal"
            value={campaign?.promotion?.code}
            onChange={(e) => handleSetPromoCode(e.target.value)}
          />

          <TextField
            disabled={role != "admin"}
            select
            label="Promotion Type"
            value={campaign?.promotion?.promoType}
            onChange={handlePromoTypeChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value="dollar">Dollar Amount</MenuItem>
            <MenuItem value="percent">Percentage</MenuItem>
            <MenuItem value="custom">Custom</MenuItem>
          </TextField>
          {campaign?.promotion?.promoType &&
            campaign?.promotion?.promoType != "custom" && (
              <TextField
                disabled={role != "admin"}
                label={
                  campaign?.promotion?.promoType === "percent"
                    ? "Percent Discount"
                    : "Dollar Amount"
                }
                variant="outlined"
                fullWidth
                margin="normal"
                value={campaign?.promotion?.discount.toString() || ""}
                onChange={handleDiscountChange}
                onBlur={handleDiscountBlur}
                InputProps={{
                  endAdornment:
                    campaign?.promotion?.promoType === "percent" ? (
                      <InputAdornment position="end">%</InputAdornment>
                    ) : null,
                  startAdornment:
                    campaign?.promotion?.promoType === "dollar" ? (
                      <InputAdornment position="start">$</InputAdornment>
                    ) : null,
                }}
              />
            )}

          {campaign?.promotion?.promoType === "custom" && (
            <>
              <TextField
                disabled={role != "admin"}
                label="Custom Offer"
                variant="outlined"
                fullWidth
                margin="normal"
                value={campaign?.promotion?.discount}
                onChange={handleCustomOfferChange}
                helperText={`${campaign?.promotion?.discount?.length}/${MAX_CUSTOM_OFFER_LENGTH}`}
              />
            </>
          )}

          <Paper
            elevation={2}
            style={{
              padding: "20px",
              borderRadius: "10px",
              width: "300px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginBottom: "2em",
              marginTop: "2em",
            }}
          >
            <Box display="flex" alignItems="center" gap="15px">
              <EventIcon color="action" />

              <Typography variant="subtitle1" component="div">
                Set Expiration Date
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    disabled={role != "admin"}
                    checked={isExpirationEnabled}
                    onChange={handleExpirationToggle}
                  />
                }
                label=""
              />
            </Box>
          </Paper>

          {isExpirationEnabled && (
            <div style={{ marginTop: "1em" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disabled={role != "admin"}
                  label="Expiration Date"
                  value={
                    campaign?.promotion?.expiration
                      ? moment(campaign.promotion.expiration)
                      : null
                  }
                  onChange={(newValue) => handleSetPromoExpiration(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PromoCodeForm;
