import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, ButtonBase, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns } from "../models/campaign/campaignSlice";
import { getBusiness } from "../models/business/businessSlice";
import { createUser, getUsers } from "../models/user/userSlice";
import CreateUserForm from "../components/Form/CreateUserForm";
import { useForm } from "react-hook-form";

const User = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "user",
  });
  const { control, handleSubmit } = useForm();

  const { businessId } = useParams();

  const businessSlice = useSelector((state) => state.business);
  const campaignsSlice = useSelector((state) => state.campaign);
  const userSlice = useSelector((state) => state.user);

  const { business } = businessSlice;
  const { campaigns } = campaignsSlice;
  const { users } = userSlice;

  console.log("the business (user page): ", business);

  const dispatch = useDispatch();

  useEffect(() => {
    if (businessId) {
      dispatch(getBusiness(businessId));
      dispatch(getCampaigns(businessId));
      dispatch(getUsers(businessId));
    }
  }, [businessId]);

  const handleCreateUser = () => {
    console.log("create user: ", user);
    if (businessId) {
      dispatch(createUser({ businessId: businessId, user: user }));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2>{`Add user to: ${business?.businessName}`}</h2>
      <h3 style={{ margin: "0px" }}>{business?.phoneNumber}</h3>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1em",
          marginTop: "2em",
        }}
      >
        <CreateUserForm
          control={control}
          handleSubmit={handleSubmit}
          user={user}
          setUser={setUser}
          handleCreateUser={handleCreateUser}
        />
      </div>
    </div>
  );
};

export default User;
