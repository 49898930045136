import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCampaignInteractions } from "../models/campaign/campaignSlice";

const CampaignInteractions = (props) => {
  const campaignSlice = useSelector((state) => state.campaign);
  const { campaign, campaignInteractions } = campaignSlice;
  const dispatch = useDispatch();

  useEffect(() => {
    if (campaign && campaign?.campaignId && campaign?.businessId) {
      const { campaignId, businessId } = campaign;

      dispatch(
        getCampaignInteractions({
          businessId: businessId,
          campaignId: campaignId,
        })
      );
    }
  }, [campaign?.campaignId]);

  console.log("campaign interactions: ", campaignInteractions);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "2em",
        }}
      >
        <div>
          <h3>Total Sent Count</h3>
          {campaignInteractions?.length ?? 0}
        </div>
        <div>
          <h3>Redeemed Count</h3>
          {campaignInteractions?.filter((interaction) => interaction.redeemed)
            .length ?? 0}
        </div>
        <div>
          <h3>Redeem Rate</h3>
          {campaignInteractions && campaignInteractions.length > 0
            ? `${(
                (campaignInteractions.filter(
                  (interaction) => interaction.redeemed
                ).length /
                  campaignInteractions.length) *
                100
              ).toFixed(2)}%`
            : "0%"}
        </div>
      </div>
    </div>
  );
};

export default CampaignInteractions;
