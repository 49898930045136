import { Paper } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import CreateBusinessForm from "../components/Form/CreateBusinessForm";
import { useDispatch, useSelector } from "react-redux";
import {
  createBusiness,
  getBusinesses,
} from "../models/business/businessSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const Businesses = (props) => {
  const { user } = props;

  const { control, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch();

  const [business, setBusiness] = useState({
    businessName: "",
    phoneNumber: "",

    fullAddress: {
      address1: "",
      city: "",
      state: "",
      zip: "",
    },
  });

  const businessSlice = useSelector((state) => state.business);

  const { businesses } = businessSlice;

  useEffect(() => {
    dispatch(getBusinesses());
  }, []);

  const handleCreateBusiness = async () => {
    dispatch(createBusiness(business))
      .then(unwrapResult)
      .then(() => {
        toast.success("Business successfully created");
      })
      .catch(() => {});
  };

  console.log("user: ", user);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: "2em",
        }}
      >
        <div>
          <h2>Create a business</h2>
          <CreateBusinessForm
            control={control}
            setValue={setValue}
            handleSubmit={handleSubmit}
            handleCreateBusiness={handleCreateBusiness}
            business={business}
            setBusiness={setBusiness}
          />
        </div>
      </div>

      <h2>Businesses</h2>
      {businesses.map((biz) => {
        return (
          <Paper
            style={{
              margin: "10px",
              padding: "10px",
              boxShadow: "0px 3px 5px rgba(0,0,0,0.2)",
              width: "300px",
              height: "100px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <p style={{ margin: "0", fontWeight: "bold" }}>
                {biz?.businessName}
              </p>
              <p style={{ margin: "0" }}>{biz?.phoneNumber}</p>
            </div>
          </Paper>
        );
      })}
    </div>
  );
};

export default Businesses;
