import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import {
  LockOutlined as LockIcon,
  EmailOutlined as EmailIcon,
} from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";

import { isValidPhoneNumber } from "libphonenumber-js";

import { AsYouType } from "libphonenumber-js";
import GooglePlacesAddress from "./GooglePlacesAddress";
import { useEffect, useRef, useState } from "react";

import _ from "lodash";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { EMAIL_REGEX } from "../../config/constants";

export const PhoneNumber = (props) => {
  const { phone, setPhone, control, name, disabled } = props;

  const handleValidatePhoneNumber = (num) => {
    const asYouType = new AsYouType("US");
    const phone = asYouType.input(num);

    const number = asYouType.getNumber()?.number;
    const isValid = isValidPhoneNumber(number);
    console.log(`number:  ${number} | valid: ${isValid}`);
    if (!isValid) {
      return "The Phone Number is invalid";
    }
  };

  return (
    <Controller
      control={control}
      name={name ? name : "phone"}
      defaultValue={!_.isNil(phone) ? phone : ""}
      rules={{
        required: {
          value: true,
          message: "Phone number is required",
        },
        validate: handleValidatePhoneNumber,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          disabled={disabled}
          label={name ? name : "Phone Number"}
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="phoneNumber"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const raw = e.target.value;
            console.log("raw: ", raw);
            const asYouType = new AsYouType("US");
            const phone = asYouType.input(raw);
            const number = asYouType.getNumber()?.number;

            console.log(`number: ${number} | length: ${number?.length}`);

            if (!number || number.length < 13) {
              onChange(number);
              setPhone(number);
            }
          }}
          value={phone ? new AsYouType("US").input(phone) : ""}
          type="tel"
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const StreetAddress = (props) => {
  const { control, streetAddress, setStreetAddress, name, required } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      rules={{
        required: {
          value: required ? true : false,
          message: "You must enter the street address",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          margin="normal"
          label="Street Address"
          type="text"
          onChange={(e) => {
            const curr = e.target.value;
            onChange(curr);
            setStreetAddress(curr);
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          required={required}
          value={streetAddress}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export const FullAddress = (props) => {
  const { control, fullAddress, setFullAddress } = props;

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "row", columnGap: "1em" }}>
        <StreetAddress
          control={control}
          name="address1"
          label="Address Line 1"
          required={true}
          streetAddress={fullAddress.address1}
          setStreetAddress={(address1) => {
            setFullAddress({ ...fullAddress, address1: address1 });
          }}
        />

        <StreetAddress
          control={control}
          name="address2"
          label="Address Line 2"
          required={false}
          streetAddress={fullAddress.address2}
          setStreetAddress={(address2) => {
            setFullAddress({ ...fullAddress, address2: address2 });
          }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "1em",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "50%",
          }}
        >
          <City
            control={control}
            city={fullAddress.city}
            setCity={(city) => {
              setFullAddress({ ...fullAddress, city: city });
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            width: "25%",
          }}
        >
          <StateCodeSelect
            control={control}
            stateCode={fullAddress.state}
            setStateCode={(state) => {
              console.log(state);
              setFullAddress({
                ...fullAddress,
                state: state,
                stateCode: state.code,
              });
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "25%",
          }}
        >
          <Zip
            control={control}
            zip={fullAddress.zip}
            setZip={(zip) => {
              setFullAddress({ ...fullAddress, zip: zip });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export const StateCodeSelect = (props) => {
  const { control, stateCode, setStateCode } = props;
  const [inputValue, setInputValue] = useState();

  const stateCodes = [
    { code: "AL", label: "Alabama" },
    {
      code: "AK",
      label: "Alaska",
    },
    { code: "AZ", label: "Arizona" },
    { code: "AR", label: "Arkansas" },
    { code: "CA", label: "California" },
    {
      code: "CO",
      label: "Colorado",
    },
    {
      code: "CT",
      label: "Connecticut",
    },
    {
      code: "DE",
      label: "Deleware",
    },
    {
      code: "DC",
      label: "District Of Columbia",
    },
    {
      code: "FL",
      label: "Florida",
    },
    {
      code: "GA",
      label: "Georgia",
    },
    {
      code: "HI",
      label: "Hiwaii",
    },
    {
      code: "ID",
      label: "Idaho",
    },
    {
      code: "IL",
      label: "Illinois",
    },
    {
      code: "IN",
      label: "Indiana",
    },
    {
      code: "IA",
      label: "Iowa",
    },
    {
      code: "KS",
      label: "Kansas",
    },
    {
      code: "KY",
      label: "Kentucky",
    },
    {
      code: "LA",
      label: "Louisiana",
    },
    {
      code: "ME",
      label: "Maine",
    },
    {
      code: "MD",
      label: "Maryland",
    },
    {
      code: "MA",
      label: "Massachusetts",
    },
    {
      code: "MI",
      label: "Michigan",
    },
    {
      code: "MI",
      label: "Minnesota",
    },
    {
      code: "MS",
      label: "Mississipi",
    },
    {
      code: "MO",
      label: "Missouri",
    },
    {
      code: "MT",
      label: "Montana",
    },
    {
      code: "NE",
      label: "Nebraska",
    },
    {
      code: "NV",
      label: "Nevada",
    },
    {
      code: "NH",
      label: "New Hampshire",
    },
    {
      code: "NJ",
      label: "New Jersey",
    },
    {
      code: "NM",
      label: "New Mexico",
    },
    {
      code: "NY",
      label: "New York",
    },
    {
      code: "NC",
      label: "North Carolina",
    },
    {
      code: "ND",
      label: "North Dakota",
    },
    {
      code: "OH",
      label: "Ohio",
    },
    {
      code: "OK",
      label: "Oklahoma",
    },
    {
      code: "OR",
      label: "Oregon",
    },
    {
      code: "PA",
      label: "Pennsylvania",
    },
    {
      code: "RI",
      label: "Rhode Island",
    },
    {
      code: "SC",
      label: "South Carolina",
    },
    {
      code: "SD",
      label: "South Dakota",
    },
    {
      code: "TN",
      label: "Tennessee",
    },
    {
      code: "TX",
      label: "Texas",
    },
    {
      code: "UT",
      label: "Utah",
    },
    {
      code: "VT",
      label: "Vermont",
    },
    {
      code: "VA",
      label: "Virginia",
    },
    {
      code: "WA",
      label: "Washington",
    },
    {
      code: "WV",
      label: "West Virgina",
    },
    {
      code: "WI",
      label: "Wisconsin",
    },
    {
      code: "WY",
      label: "Wyoming",
    },
  ];

  return (
    <Controller
      control={control}
      name="stateCode"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "You must enter the state code",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <Autocomplete
          id="state-select"
          value={stateCode}
          onChange={(event, newValue) => {
            console.log("changed: ", newValue);
            onChange(newValue);
            setStateCode(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          sx={{ width: 300 }}
          options={stateCodes}
          autoHighlight
          getOptionLabel={(option) => option.label || ""}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option.label} ({option.code})
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select a State"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
              fullWidth
              margin="normal"
            />
          )}
        />
      )}
    />
  );
};

export const City = (props) => {
  const { control, city, setCity } = props;

  return (
    <Controller
      control={control}
      name="city"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "You must enter the city",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          margin="normal"
          label="City"
          type="text"
          onChange={(e) => {
            const curr = e.target.value;
            onChange(curr);
            setCity(curr);
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          required
          value={city}
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};

export const Email = (props) => {
  const { email, setEmail, control, name } = props;

  return (
    <Controller
      control={control}
      name="email"
      defaultValue={!_.isNil(email) ? email : ""}
      rules={{
        required: {
          value: true,
          message: "Email is required",
        },
        pattern: {
          value: EMAIL_REGEX,
          message: "Not a valid email",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label={name ? name : "Email Address"}
          margin="normal"
          required
          fullWidth
          name="email"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const email = e.target.value;

            onChange(email);
            setEmail(email);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
          value={email}
          type="email"
          autoComplete="off"
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const BusinessName = (props) => {
  const { businessName, setBusinessName, control } = props;

  return (
    <Controller
      control={control}
      name="businessName"
      defaultValue={!_.isNil(businessName) ? businessName : ""}
      rules={{
        required: {
          value: true,
          message: "Business Name is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label="Business Name"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="businessName"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const businessName = e.target.value;
            onChange(businessName);
            setBusinessName(businessName);
          }}
          value={businessName}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const FirstName = (props) => {
  const { firstName, setFirstName, control } = props;

  return (
    <Controller
      control={control}
      name="firstName"
      defaultValue={!_.isNil(firstName) ? firstName : ""}
      rules={{
        required: {
          value: true,
          message: "First Name is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label="First Name"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="firstName"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const firstName = e.target.value;
            onChange(firstName);
            setFirstName(firstName);
          }}
          value={firstName}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const LastName = (props) => {
  const { lastName, setLastName, control } = props;

  return (
    <Controller
      control={control}
      name="lastName"
      defaultValue={!_.isNil(lastName) ? lastName : ""}
      rules={{
        required: {
          value: true,
          message: "Last Name is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          label="Last Name"
          margin="normal"
          required
          fullWidth
          name="lastName"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const lastName = e.target.value;
            onChange(lastName);
            setLastName(lastName);
          }}
          value={lastName}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const Zip = (props) => {
  const { zip, setZip, control, disabled, name } = props;

  return (
    <Controller
      control={control}
      name={name ? `${name}-zip` : "zip"}
      defaultValue={zip}
      rules={{
        required: {
          value: true,
          message: "Zip is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          disabled={disabled}
          label="Zip Code"
          margin="normal"
          style={{ backgroundColor: "white" }}
          required
          fullWidth
          name="zip"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            const zip = e.target.value;
            const regex = /^[0-9]*$/;
            if (zip === "" || regex.test(zip)) {
              onChange(zip);
              setZip(zip);
            }
          }}
          inputProps={{ maxLength: 5 }}
          value={zip}
          error={error !== undefined}
          helperText={error ? error.message : ""}
        />
      )}
    />
  );
};

export const Address = (props) => {
  const {
    place,
    setPlace,
    setAddressComponents,
    control,
    name,
    label,
    disabled,
  } = props;

  console.log("logging place: ", place);

  const placesService = { current: null };

  const callback = (placeResult, status) => {
    console.log("inside of the callback");
    console.log(`the place details result: `, placeResult);

    if (status == window.google.maps.places.PlacesServiceStatus.OK) {
      console.log("set address components 1: ", placeResult);
      setAddressComponents(placeResult);
    }
  };

  useEffect(() => {
    if (_.isNil(place)) {
      console.log("the place was nil");
      setAddressComponents();
      return;
    }

    if (!placesService.current && window.google) {
      let map = new window.google.maps.Map(document.createElement("div"));
      placesService.current = new window.google.maps.places.PlacesService(map);
    }

    if (!placesService.current) {
      return undefined;
    }

    const request = {
      placeId: place["place_id"],
      fields: [
        "address_components",
        "formatted_address",
        "formatted_phone_number",
        "name",
        "photos",
      ],
    };

    console.log("making place details request with place: ", place);

    if (!_.isNil(place["place_id"])) {
      placesService.current.getDetails(request, callback);
    }
  }, [place]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        columnGap: "1em",
      }}
    >
      <Controller
        control={control}
        name={name ? `${name}-address` : "address"}
        defaultValue={!_.isNil(place) ? place : ""}
        rules={{
          required: {
            value: true,
            message: "Address is required",
          },
        }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <GooglePlacesAddress
            disabled={disabled}
            label={label}
            value={place}
            setValue={setPlace}
            onChange={onChange}
            error={error}
          />
        )}
      />
    </div>
  );
};

export const AddressWithZip = (props) => {
  const { control, address, setAddress, name, setValue, label, disabled } =
    props;

  const zipFieldName = name ? `${name}-zip` : "zip";

  console.log("given address: ", address);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "1em",
        width: "100%",
      }}
    >
      <div style={{ width: "80%" }}>
        <Address
          disabled={disabled}
          label={label}
          name={name}
          control={control}
          place={
            address?.place
              ? address?.place
              : {
                  description: !_.isNil(address?.description)
                    ? address.description
                    : "",
                  ["place_id"]: address?.placeId,
                }
          }
          setPlace={(place) => {
            setAddress({ ...address, place: place });
          }}
          setAddressComponents={(placeDetails) => {
            console.log("set address components 2: ", placeDetails);
            if (_.isNil(placeDetails)) {
              setAddress({ ...address, city: "", state: "", zip: "" });
              setValue(zipFieldName, "", {
                shouldValidate: true,
              });

              return;
            }

            const formattedPhoneNumber = placeDetails["formatted_phone_number"];
            const photos = placeDetails["photos"]?.map((photo) => {
              return photo.getUrl();
            });
            const name = placeDetails["name"];

            const addressComponents = placeDetails["address_components"];

            console.log("address components: ", addressComponents);

            const streetNumComponent = addressComponents.filter((component) => {
              return component["types"].includes("street_number");
            });

            const routeComponent = addressComponents.filter((component) => {
              return component["types"].includes("route");
            });

            const cityComponent = addressComponents.filter((component) => {
              return component["types"].includes("locality");
            });
            const stateComponent = addressComponents.filter((component) => {
              return component["types"].includes("administrative_area_level_1");
            });
            const zipComponent = addressComponents.filter((component) => {
              return component["types"].includes("postal_code");
            });

            let streetNum;
            let street;
            let addressCity;
            let addressState;
            let addressZip;

            if (
              !_.isNil(streetNumComponent) &&
              streetNumComponent.length != 0
            ) {
              console.log(
                "setting streetNum: ",
                streetNumComponent[0]["short_name"]
              );
              streetNum = streetNumComponent[0]["short_name"];
            }

            if (!_.isNil(routeComponent) && routeComponent.length != 0) {
              console.log("setting street: ", routeComponent[0]["short_name"]);
              street = routeComponent[0]["short_name"];
            }

            if (!_.isNil(cityComponent) && cityComponent.length != 0) {
              console.log("setting city: ", cityComponent[0]["short_name"]);
              addressCity = cityComponent[0]["short_name"];
            }

            if (!_.isNil(stateComponent) && stateComponent.length != 0) {
              console.log("setting state: ", stateComponent[0]["short_name"]);
              addressState = stateComponent[0]["short_name"];
            }

            if (!_.isNil(zipComponent) && zipComponent.length != 0) {
              console.log("setting zip: ", zipComponent[0]["short_name"]);

              setValue(zipFieldName, zipComponent[0]["short_name"], {
                shouldValidate: true,
              });

              addressZip = zipComponent[0]["short_name"];
            }

            setAddress({
              ...address,
              addressLine1: `${streetNum} ${street}`,
              city: addressCity,
              state: addressState,
              zip: Number(addressZip),
              formattedPhoneNumber: formattedPhoneNumber,
              photos: photos,
              name: name,
            });
          }}
        />
      </div>

      <div style={{ width: isMobile ? "25%" : "15%" }}>
        <Zip name={name} control={control} zip={address?.zip} disabled={true} />
      </div>
    </div>
  );
};

export const PasswordWithStrength = (props) => {
  const { password, setPassword, control } = props;
  const [passwordVisible, setPasswordVisible] = useState();
  const [meter, setMeter] = useState(false);

  const passwordRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/g;
  const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
  const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
  const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
  const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
  const eightCharsOrMore = /.{8,}/g; // eight characters or more

  const passwordTracker = {
    uppercase: password.match(atLeastOneUppercase),
    lowercase: password.match(atLeastOneLowercase),
    number: password.match(atLeastOneNumeric),
    specialChar: password.match(atLeastOneSpecialChar),
    eightCharsOrGreater: password.match(eightCharsOrMore),
  };

  const passwordStrength = Object.values(passwordTracker).filter(
    (value) => value
  ).length;

  const createErrorMessage = () => {
    let errors = [];

    if (passwordStrength < 5) {
      if (!passwordTracker.uppercase) {
        errors = [...errors, "at least 1 uppercase character"];
      }

      if (!passwordTracker.lowercase) {
        errors = [...errors, "at least 1 lowercase character"];
      }

      if (!passwordTracker.specialChar) {
        errors = [...errors, "at least 1 special character"];
      }

      if (!passwordTracker.number) {
        errors = [...errors, "at least 1 number"];
      }

      if (!passwordTracker.eightCharsOrGreater) {
        errors = [...errors, "be eight characters or more"];
      }
    }

    if (!_.isNil(errors) && errors.length != 0) {
      return "Your password must contain: " + errors.join(", ");
    }

    return errors;
  };

  return (
    <Controller
      control={control}
      name="password"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "Password is required",
        },

        pattern: {
          value: passwordRegex,
          message: "The password does not meet the requirements",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextField
            autoComplete="off-password"
            margin="normal"
            label="Password"
            type={passwordVisible ? "text" : "password"}
            onFocus={() => setMeter(true)}
            onChange={(e) => {
              const curr = e.target.value;
              onChange(curr);
              setPassword(curr);
            }}
            error={error !== undefined}
            required
            value={password}
            fullWidth
            InputLabelProps={{ shrink: true }}
            InputProps={{
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    {passwordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {meter && (
            <div>
              <div className="password-strength-meter"></div>

              <div>
                <p style={{ fontSize: "13px" }}>{createErrorMessage()}</p>
              </div>
            </div>
          )}
          <style jsx>
            {`
              .password-strength-meter {
                height: 0.3rem;
                background-color: lightgrey;
                border-radius: 3px;
                margin: 0.5rem 0;
              }

              .password-strength-meter::before {
                content: "";
                background-color: ${[
                  "red",
                  "orange",
                  "#03a2cc",
                  "#03a2cc",
                  "#0ce052",
                ][passwordStrength - 1] || ""};
                height: 100%;
                width: ${(passwordStrength / 5) * 100}%;
                display: block;
                border-radius: 3px;
                transition: width 0.2s;
              }
            `}
          </style>
        </div>
      )}
    />
  );
};

export const ConfirmPassword = (props) => {
  const { password, confirmPassword, setConfirmPassword, control } = props;
  const [passwordVisible, setPasswordVisible] = useState();

  const handleValidatePassword = (curr) => {
    if (curr != password) {
      return "The passwords do not match";
    }
  };

  return (
    <Controller
      control={control}
      name="confirmPassword"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "You must confirm your password",
        },

        validate: handleValidatePassword,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          autoComplete="new-password"
          margin="normal"
          label="Confirm Password"
          type={passwordVisible ? "text" : "password"}
          onChange={(e) => {
            const curr = e.target.value;
            onChange(curr);
            setConfirmPassword(curr);
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          required
          value={confirmPassword}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            autoComplete: "new-password",
            form: {
              autoComplete: "off",
            },
            startAdornment: (
              <InputAdornment position="start">
                <LockIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export const CreatePassword = (props) => {
  const {
    control,
    password,
    confirmPassword,
    setPassword,
    setConfirmPassword,
  } = props;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        columnGap: isMobile ? "0" : "1em",
      }}
    >
      <div style={{ width: "100%" }}>
        <PasswordWithStrength
          control={control}
          password={password}
          setPassword={(curr) => {
            setPassword(curr);
          }}
        />
      </div>
      <div style={{ width: "100%" }}>
        <ConfirmPassword
          control={control}
          password={password}
          confirmPassword={confirmPassword}
          setConfirmPassword={(curr) => {
            setConfirmPassword(curr);
          }}
        />
      </div>
    </div>
  );
};

export const EnterPassword = (props) => {
  const { password, setPassword, control } = props;
  const [passwordVisible, setPasswordVisible] = useState();

  return (
    <Controller
      control={control}
      name="confirmPassword"
      defaultValue=""
      rules={{
        required: {
          value: true,
          message: "Password is required",
        },
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <TextField
          margin="normal"
          label="Password"
          autoComplete="new-password"
          type={passwordVisible ? "text" : "password"}
          onChange={(e) => {
            const curr = e.target.value;
            onChange(curr);
            setPassword(curr);
          }}
          error={error !== undefined}
          helperText={error ? error.message : ""}
          required
          value={password}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            form: {
              autoComplete: "off",
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export const PinPad = (props) => {
  const { pinLength, pins, setPins, control, errors } = props;
  const pinRefs = useRef([]);
  const hiddenInputRef = useRef(null);

  useEffect(() => {
    if (pinRefs.current[0]) {
      pinRefs.current[0].focus();
    }
  }, []);

  let pinError =
    !_.isNil(errors) &&
    Object.keys(errors).some((error) => error.includes("pins-"));

  const handleInput = (e, index, onChange) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;

    if (!regex.test(value)) {
      return; // Ignore non-numeric input
    }

    const newPins = [...pins];
    newPins[index] = value;
    setPins(newPins);
    onChange(newPins[index]);

    if (value && index < pinLength - 1) {
      pinRefs.current[index + 1].focus();
    }

    if (value.length > 1) {
      // Handle autofill
      const autofillValues = value.slice(0, pinLength).split("");
      autofillValues.forEach((val, idx) => {
        if (pinRefs.current[idx]) {
          pinRefs.current[idx].value = val;
          newPins[idx] = val;
        }
      });
      setPins(newPins);
      const nextIndex =
        autofillValues.length < pinLength
          ? autofillValues.length
          : pinLength - 1;
      if (pinRefs.current[nextIndex]) {
        pinRefs.current[nextIndex].focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").slice(0, pinLength);
    const newPins = pastedData.split("");

    setPins(newPins);
    newPins.forEach((pin, idx) => {
      if (pinRefs.current[idx]) {
        pinRefs.current[idx].value = pin; // Explicitly set the value of each input
      }
    });

    // Focus on the next input after the last pasted character
    const nextIndex =
      newPins.length < pinLength ? newPins.length : pinLength - 1;
    if (pinRefs.current[nextIndex]) {
      pinRefs.current[nextIndex].focus();
    }
  };

  const handleBackspace = (e, index, onChange) => {
    if (e.key === "Backspace" && !e.target.value) {
      const prevIndex = index > 0 ? index - 1 : 0;
      pinRefs.current[prevIndex].focus();
      const newPins = [...pins];
      newPins[prevIndex] = "";
      setPins(newPins);
      onChange(newPins[prevIndex]);
      e.preventDefault();
    }
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleHiddenInputChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;

    if (value.length === pinLength && regex.test(value)) {
      const newPins = value.split("");
      setPins(newPins);
      newPins.forEach((pin, idx) => {
        if (pinRefs.current[idx]) {
          pinRefs.current[idx].value = pin;
        }
      });
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          columnGap: "1em",
          flexDirection: "row",
        }}
      >
        <input
          type="tel"
          value={Array.isArray(pins) ? pins.join("") : ""}
          onChange={handleHiddenInputChange}
          ref={hiddenInputRef}
          style={{
            visibility: "hidden",
            position: "absolute",
            left: "-9999px",
          }}
          autoComplete="one-time-code"
        />
        {[...Array(pinLength).keys()].map((index) => (
          <Controller
            control={control}
            name={`pins-${index}`}
            defaultValue=""
            rules={{ required: { value: true } }}
            render={({ field: { onChange } }) => (
              <TextField
                autoComplete="one-time-code"
                margin="normal"
                style={{ backgroundColor: "white" }}
                required
                value={pins[index] || ""}
                id={`pin-${index}`}
                key={`pin-${index}`}
                name={`pin-${index}`}
                inputRef={(input) => (pinRefs.current[index] = input)}
                variant="outlined"
                inputProps={{ maxLength: 1, type: "tel" }}
                InputLabelProps={{ shrink: true }}
                error={errors[`pins-${index}`] !== undefined}
                onFocus={handleFocus}
                onChange={(e) => handleInput(e, index, onChange)}
                onInput={(e) => handleInput(e, index, onChange)}
                onKeyDown={(e) => handleBackspace(e, index, onChange)}
                onPaste={handlePaste}
              />
            )}
          />
        ))}
      </div>
      {pinError && <p>You must enter the 6 digit verification code</p>}
    </div>
  );
};
