import { useEffect, useState } from "react";
import { AddressWithZip, BusinessName, FullAddress, PhoneNumber } from "./Form";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { ROOT_URL } from "../../config/constants";
import axios from "axios";

const CreateBusinessForm = (props) => {
  const {
    control,
    setValue,
    handleSubmit,
    business,
    setBusiness,
    handleCreateBusiness,
  } = props;

  const [subAccounts, setSubAccounts] = useState([]);

  const isAdmin = sessionStorage.getItem("role") === "admin";

  return (
    <div>
      <BusinessName
        businessName={business?.businessName}
        setBusinessName={(businessName) => {
          setBusiness({
            ...business,
            businessName: businessName,
          });
        }}
        control={control}
        name="businessName"
        label="Legal Business Name"
      />

      <PhoneNumber
        name="Business Phone Number"
        control={control}
        phone={business?.phoneNumber}
        setPhone={(phone) => {
          setBusiness({ ...business, phoneNumber: phone });
        }}
      />

      <FullAddress
        control={control}
        fullAddress={business?.fullAddress}
        setValue={setValue}
        setFullAddress={(address) => {
          setBusiness({ ...business, fullAddress: address });
        }}
      />
      <Button variant={"outlined"} onClick={handleSubmit(handleCreateBusiness)}>
        Create Business
      </Button>
    </div>
  );
};

export default CreateBusinessForm;
