import FileDropzone from "./FileDropzone";
import CampaignBlastForm from "./Form/CampaignBlastForm";
import StyledTable from "./StyledTable";
import { useEffect, useMemo, useState } from "react";
import Papa from "papaparse";
import MessagePreview from "./MessagePreview";
import { isValidPhoneNumber } from "libphonenumber-js";
import { toast } from "react-toastify";
import Popover from "@mui/material/Popover";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SwipeableTabs from "./SwipableTabs";
import CampaignInteractions from "./CampaignInteractions";
import useIsMobile from "../hooks/useIsMobile";

const CampaignComponent = (props) => {
  const isMobile = useIsMobile();

  const { campaign, setCampaign, control } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [jsonFile, setJsonFile] = useState(null);
  const [fileErrors, setFileErrors] = useState([]);

  const columns = useMemo(() => {
    if (jsonFile) {
      const { fields } = jsonFile?.meta;

      console.log("the fields: ", fields);
      return fields?.length > 0
        ? fields?.map((fieldName) => ({
            Header: fieldName,
            accessor: fieldName,
          }))
        : [];
    }
  }, [jsonFile]);

  useEffect(() => {
    if (campaign?.base64FileContent) {
      console.log("inside get campaign use effect 2: ", campaign);

      const decodedFileContent = atob(campaign?.base64FileContent);

      // Use Blob to create a file-like object
      const csvBlob = new Blob([decodedFileContent], { type: "text/csv" });
      const csvFile = new File([csvBlob], campaign?.fileName);

      Papa.parse(csvFile, {
        complete: (result) => {
          console.log("Parsed Result:", result);
          setJsonFile(result);
        },
        header: true,
      });
    }
  }, [campaign?.base64FileContent]);

  useEffect(() => {
    if (jsonFile) {
      const validationErrors = validateJsonFile(jsonFile);

      console.log("validation errors: ", validationErrors);

      if (validationErrors.length > 0) {
        // Create a formatted string from the errors
        const formattedErrors = validationErrors.join("\n");

        setFileErrors(formattedErrors);

        // Display the toast
        toast.error(
          <div>
            <strong>The file was invalid</strong>
            <div>{formattedErrors}</div>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } else {
        setFileErrors(null);
      }
    }
  }, [jsonFile]);

  const validateJsonFile = (jsonFile) => {
    const errors = [];
    const { data, meta } = jsonFile;

    const normalizedFields = meta.fields.map((field) =>
      field.trim().toLowerCase()
    );

    // Check if required fields are present
    const requiredFields = ["name", "number"];
    const missingFields = requiredFields.filter(
      (field) => !normalizedFields.includes(field)
    );
    missingFields.forEach((field) =>
      errors.push(`The column '${field}' is missing`)
    );

    // Validate data rows
    data.forEach((row, index) => {
      if (row.name && row.name.length > 120) {
        errors.push(`The name in row ${index + 1} exceeds 120 characters`);
      }
      if (row.number && !isValidPhoneNumber(row.number, "US")) {
        errors.push(
          `The number in row ${index + 1} is not a valid US phone number`
        );
      }
    });

    return errors;
  };

  const handleFileUpload = (files) => {
    console.log("Received files:", files);

    const rawFile = files[0];

    const { name, size } = rawFile;

    const reader = new FileReader();
    reader.readAsDataURL(rawFile);
    reader.onload = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      setCampaign((prevCampaign) => ({
        ...prevCampaign,
        base64FileContent: base64String,
        audienceFileName: name,
        audienceFileSize: size,
      }));
    };
    reader.onerror = (error) => {
      console.log("Error reading file:", error);
    };
  };

  const campaignDetailsTab = (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          width: isMobile ? "100%" : "60%",
        }}
      >
        <h2>Campaign Details</h2>
        <CampaignBlastForm
          campaign={campaign}
          setCampaign={setCampaign}
          control={control}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: isMobile ? "100%" : "40%",
        }}
      >
        <MessagePreview
          imageFile={campaign?.messageImage}
          message={campaign?.messageBody}
        />
      </div>
    </div>
  );

  const audienceTab = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        gap: "1em",
      }}
    >
      <div style={{ display: "flex", width: isMobile ? "100%" : "350px" }}>
        <FileDropzone
          onFileUpload={handleFileUpload}
          fileName={campaign?.audienceFileName}
          fileSize={campaign?.audienceFileSize}
          acceptedFileTypes={{
            "text/csv": [".csv"],
          }}
          dropzoneMessage="Drag and drop your audience CSV file here, or click to select files"
        />
      </div>
      {jsonFile?.data && fileErrors ? (
        <div style={{ cursor: "pointer" }}>
          <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <ErrorOutlineIcon color="error" />

            <Typography
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              The file is invalid
            </Typography>
          </div>

          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography style={{ padding: "10px" }}>{fileErrors}</Typography>
          </Popover>
        </div>
      ) : (
        jsonFile?.data && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon style={{ color: "green" }} />
            <Typography>
              This file is valid, and the audience size is{" "}
              {jsonFile?.data.length}
            </Typography>
          </div>
        )
      )}
      {jsonFile?.data?.length > 0 && (
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: isMobile ? "100%" : "60%",
          }}
        >
          <StyledTable columns={columns} data={jsonFile?.data} />
        </div>
      )}
    </div>
  );

  const interactionsTab = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        gap: "1em",
      }}
    >
      <CampaignInteractions />
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: isMobile ? "auto" : "100%",
        padding: isMobile ? "0em" : "2em",
        boxSizing: "border-box",
      }}
    >
      <SwipeableTabs
        tabs={[
          { label: "Campaign Details", content: campaignDetailsTab },
          { label: "Audience", content: audienceTab },
          { label: "Interactions", content: interactionsTab },
        ]}
      />
    </div>
  );
};

export default CampaignComponent;
