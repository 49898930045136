import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axiosConfig";

export const searchPromotion = createAsyncThunk(
  "promotions/searchPromotion",
  async (promotionDetails) => {
    const response = await axiosInstance.post(`validatePromoCode`, {
      ...promotionDetails,
      businessId: promotionDetails?.business,
    });
    return response.data;
  }
);

export const redeemPromotion = createAsyncThunk(
  "promotions/redeemPromotion",
  async (promotionDetails) => {
    const response = await axiosInstance.post(`redeemOffer`, {
      ...promotionDetails,
      businessId: promotionDetails?.business,
    });
    return response.data;
  }
);

const promotionsSlice = createSlice({
  name: "promotions",
  initialState: {
    searchPromotionResult: null,
    redeemPromotionResult: null,
    loading: false,
    error: null,
  },
  reducers: {
    resetSearchPromotionResult: (state) => {
      state.searchPromotionResult = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchPromotion.fulfilled, (state, action) => {
        state.loading = false;
        state.searchPromotionResult = action.payload;
      })
      .addCase(redeemPromotion.fulfilled, (state, action) => {
        state.loading = false;
        state.redeemPromotionResult = action.payload;
      })
      .addMatcher(
        (action) =>
          action.type.startsWith("promotions/") &&
          action.type.endsWith("/pending"),
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        (action) =>
          action.type.startsWith("promotions/") &&
          action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

export const { resetSearchPromotionResult } = promotionsSlice.actions;

export default promotionsSlice.reducer;
