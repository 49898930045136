import { Button, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { PhoneNumber } from "./Form";
import BusinessSelect from "./BusinessSelect";

const SearchRedeemPromotionForm = (props) => {
  const {
    control,
    handleSubmit,
    handleAction,
    promotionDetails,
    setPromotionDetails,
    action,
  } = props;

  const isAdmin = sessionStorage.getItem("role") === "admin";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        width: "300px",
      }}
    >
      {isAdmin && (
        <BusinessSelect
          name={`select-business-${action}`}
          business={promotionDetails?.business}
          setBusiness={(business) => {
            setPromotionDetails({ ...promotionDetails, business: business });
          }}
          control={control}
        />
      )}

      {action == "redeem" && (
        <PhoneNumber
          name={`Customer Phone Number - ${action}`}
          control={control}
          phone={promotionDetails?.customerNumber}
          setPhone={(phone) => {
            setPromotionDetails({ ...promotionDetails, customerNumber: phone });
          }}
        />
      )}
      <Controller
        control={control}
        name={`promoCode-${action}`}
        defaultValue=""
        rules={{
          required: {
            value: true,
            message: "You must enter the promo code",
          },
        }}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <TextField
            label="Promotion Code"
            margin="normal"
            fullWidth
            required={true}
            value={promotionDetails?.code}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              let curr = e.target.value;

              onChange(curr);
              setPromotionDetails({
                ...promotionDetails,
                code: curr,
              });
            }}
            error={error !== undefined}
            helperText={error ? error.message : ""}
          />
        )}
      />

      <Button variant="outlined" onClick={handleSubmit(handleAction)}>
        {action == "search" ? "Valdate" : "Redeem"}
      </Button>
    </div>
  );
};

export default SearchRedeemPromotionForm;
