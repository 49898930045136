import { Controller, useForm } from "react-hook-form";
import { PhoneNumber } from "./Form";
import { Button, TextField } from "@mui/material";

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { ROOT_URL } from "../../config/constants";
import { Campaign } from "@mui/icons-material";
import axiosInstance from "../../models/axiosConfig";
import { useParams } from "react-router-dom";

const PhoneOrMessagingServiceDropdownComponent = (props) => {
  const { businessId } = useParams();

  const { setSelectedNumber, setSelectedService, campaign } = props;
  const [selection, setSelection] = useState("");
  const [numbers, setNumbers] = useState([]);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);

  const isAdmin = sessionStorage.getItem("role") === "admin";

  const handleSetSelectedNumber = (num) => {
    setSelectedNumber(num);
  };

  const handleSetSelectedService = (serv) => {
    setSelectedService(serv);
  };

  useEffect(() => {
    console.log("alw : ", campaign);
    if (campaign?.fromNumber) {
      console.log("setting selection to phone number");
      setSelection("phoneNumber");
    } else if (campaign?.messagingServiceSID) {
      setSelection("messagingService");
      console.log("setting selection to messaging service");
    }
  }, [campaign]);

  useEffect(() => {
    if (selection === "phoneNumber") {
      fetchNumbers();
    } else if (selection === "messagingService") {
      fetchServices();
    }
  }, [selection]);

  const fetchNumbers = async () => {
    setLoading(true);
    try {
      let response;

      if (!businessId) {
        response = await axiosInstance.get(`listNumbers`);
      } else {
        response = await axiosInstance.get(
          `business/${businessId}/listNumbers`
        );
      }

      setNumbers(response.data?.numbers);
    } catch (error) {
      console.error("Error fetching phone numbers:", error);
    }
    setLoading(false);
  };

  const fetchServices = async () => {
    setLoading(true);
    try {
      let response;

      if (!businessId) {
        response = await axiosInstance.get(`listMessagingServices`);
      } else {
        response = await axiosInstance.get(
          `business/${businessId}/listMessagingServices`
        );
      }
      setServices(response.data?.services);
    } catch (error) {
      console.error("Error fetching messaging services:", error);
    }
    setLoading(false);
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">From</InputLabel>
        <Select
          disabled={!isAdmin}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selection}
          label="From"
          onChange={(e) => {
            setSelection(e.target.value);
            setSelectedNumber(null);
            setSelectedService(null);
          }}
        >
          <MenuItem value="phoneNumber">Phone Number</MenuItem>
          <MenuItem value="messagingService">Messaging Service</MenuItem>
        </Select>
      </FormControl>

      {loading && <CircularProgress />}

      {selection === "phoneNumber" && (
        <FormControl fullWidth style={{ marginTop: 20 }}>
          <InputLabel id="number-select-label">Number</InputLabel>
          <Select
            disabled={!isAdmin}
            labelId="number-select-label"
            id="number-select"
            label="Number"
            value={campaign?.fromNumber}
            onChange={(e) => handleSetSelectedNumber(e.target.value)}
          >
            {numbers.map((number, index) => (
              <MenuItem key={index} value={number}>
                {number}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {selection === "messagingService" && (
        <FormControl fullWidth style={{ marginTop: 20 }}>
          <InputLabel id="service-select-label">Service</InputLabel>
          <Select
            disabled={!isAdmin}
            labelId="service-select-label"
            id="service-select"
            label="Service"
            value={campaign?.messagingServiceSID}
            onChange={(e) => handleSetSelectedService(e.target.value)}
          >
            {services.map((service, index) => (
              <MenuItem key={index} value={service}>
                {service}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default PhoneOrMessagingServiceDropdownComponent;
