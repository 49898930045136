import { Amplify } from "aws-amplify";

import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import awsExports from "./aws-exports";
import React, { useEffect, useState } from "react";
import Home from "./screens/AdminHome";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Business from "./screens/Business";
import Businesses from "./screens/Businesses";
import Campaigns from "./screens/Campaigns";

import Campaign from "./screens/Campaign";
import Playground from "./screens/Playground";
import { CLIENT_URL } from "./config/constants";
import Promotions from "./screens/Promotions";
import LoadingOverlay from "./components/LoadingOverlay";
import ErrorToast from "./components/ErrorToast";
import { lightTheme, darkTheme } from "./theme";
import { ThemeProvider, CssBaseline } from "@mui/material";

import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { Switch } from "@mui/material";
import User from "./screens/User";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "./models/axiosConfig";
import { getCurrentUser } from "@aws-amplify/auth";
import UserHome from "./screens/UserHome";
import AdminHome from "./screens/AdminHome";

import { Hub } from "aws-amplify/utils";

Amplify.configure({
  Auth: {
    Cognito: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolClientId: awsExports.USER_POOL_APP_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: "ls-sms-marking.auth.us-west-2.amazoncognito.com",

          scopes: ["email", "profile", "openid"],
          redirectSignIn: [CLIENT_URL],
          redirectSignOut: [CLIENT_URL],
          responseType: "code",
          providers: ["Google"],
        },
        email: "true",
        phone: "true",
        given_name: "true",
        family_name: "true",
      },
    },
  },
});

function App({ signOut, user }) {
  const [darkMode, setDarkMode] = useState(false);

  const [role, setRole] = useState("user");
  const [businessId, setBusinessId] = useState(null);

  console.log("the logged in user: ", user);

  useEffect(() => {
    const fetchBusinessId = async (userId) => {
      try {
        const response = await axiosInstance.get(`user/${userId}`);
        console.log("response: ", response);

        const businessId = response?.data?.businessId;
        const role = response?.data?.role;

        if (!role) {
          console.log("there was an error getting the role");
        } else {
          setRole(role);

          if (businessId) {
            console.log("setting businessId: ", businessId);
            setBusinessId(businessId);
          }
        }
      } catch (error) {
        console.error("Error fetching businessId:", error);
      }
    };

    if (user && user?.userId) {
      console.log("the user: ", user);
      const userId = user?.userId;
      fetchBusinessId(userId);
    } else {
      sessionStorage.removeItem("businessId");
    }
  }, [user]);

  useEffect(() => {
    const listener = (data) => {
      console.log("inside of listener");
      const { payload } = data;
      if (payload.event === "signedOut") {
        console.log("signing out user");
        sessionStorage.clear();
      }
    };

    const hubListenerCancelToken = Hub.listen("auth", listener);

    return () => {
      hubListenerCancelToken();
    };
  }, []);

  useEffect(() => {
    console.log("setting role: ", role);
    sessionStorage.setItem("role", role);
  }, [role]);

  useEffect(() => {
    console.log("setting businessId: ", businessId);
    sessionStorage.setItem("businessId", businessId);
  }, [businessId, role]);

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "flex-end",
          paddingTop: "1em",
          paddingLeft: "2em",
          paddingRight: "2em",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LightModeIcon style={{ marginRight: 8 }} />
          <Switch
            checked={darkMode}
            onChange={() => setDarkMode(!darkMode)}
            inputProps={{ "aria-label": "Dark mode switch" }}
          />
          <DarkModeIcon style={{ marginLeft: 8 }} />
        </div>
      </div> */}
      <Router>
        <LoadingOverlay />
        <ErrorToast />

        <NavBar role={role} user={user} signOut={signOut} />
        <Routes>
          <Route
            path="/"
            element={
              role === "admin" ? (
                <AdminHome />
              ) : (
                <UserHome businessId={businessId} />
              )
            }
          />

          <Route path="/businesses" element={<Businesses />} />
          <Route path="/business/:businessId" element={<Business />} />
          <Route path="/promotions" element={<Promotions />} />
          <Route
            path="/business/:businessId/campaigns"
            element={<Campaigns />}
          />
          <Route
            path="/business/:businessId/campaign/:campaignId"
            element={<Campaign />}
          />
          {role === "admin" && (
            <>
              <Route path="/business/:businessId/users" element={<User />} />

              <Route path="/playground" element={<Playground />} />
            </>
          )}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default withAuthenticator(App, {
  socialProviders: ["google"],
});
