import { useForm } from "react-hook-form";
import RedeemPromotionForm from "../components/Form/SearchRedeemPromotionForm";
import { useEffect, useState } from "react";

import { Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import promotionsSlice, {
  redeemPromotion,
  resetSearchPromotionResult,
  searchPromotion,
} from "../models/promotions/promotionsSlice";
import SearchRedeemPromotionForm from "../components/Form/SearchRedeemPromotionForm";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import useIsMobile from "../hooks/useIsMobile";

const defaultSearchPromotionDetails = {
  code: "",
};

const defaultRedeemPromotionDetails = {
  code: "",
  customerNumber: "",
};

const Promotions = (props) => {
  const isMobile = useIsMobile();

  const promoCardStyle = {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "10px",
    width: isMobile ? "100%" : "400px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: "2em",
  };

  const isAdmin = sessionStorage.getItem("role") === "admin";

  useEffect(() => {
    if (!isAdmin) {
      const businessId = sessionStorage.getItem("businessId");
      setSearchPromotionDetails({
        ...searchPromotionDetails,
        businessId: businessId,
      });
    }
  }, []);

  const navigate = useNavigate();

  // First form instance
  const { control: searchPromoControl, handleSubmit: handleSearchPromoSubmit } =
    useForm();

  // Second form instance
  const { control: redeemPromoControl, handleSubmit: handleRedeemPromoSubmit } =
    useForm();

  const [searchPromotionDetails, setSearchPromotionDetails] = useState(
    defaultSearchPromotionDetails
  );

  const [redeemPromotionDetails, setRedeemPromotionDetails] = useState(
    defaultRedeemPromotionDetails
  );

  const promotionsSlice = useSelector((state) => state.promotions);

  const { searchPromotionResult, redeemPromotionResult } = promotionsSlice;

  const dispatch = useDispatch();

  const handleValidate = () => {
    dispatch(searchPromotion(searchPromotionDetails));
  };

  const handleRedeem = () => {
    console.log("redeem promotion details: ", redeemPromotionDetails);

    dispatch(redeemPromotion(redeemPromotionDetails))
      .then(unwrapResult)
      .then(() => {
        toast.success("The promo code was redeemed successfully");
      })
      .catch(() => {});
  };

  useEffect(() => {
    return () => {
      dispatch(resetSearchPromotionResult());
    };
  }, [dispatch]);

  const formatDiscount = (type, amount) => {
    if (!type || !amount) return "";
    switch (type) {
      case "dollar":
        return `$${amount}`;
      case "percent":
        return `${amount}%`;
      case "custom":
      default:
        return amount;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        gap: "1em",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1em",
            marginTop: "2em",
            alignItems: "center",
          }}
        >
          <LocalOfferIcon color="action" />
          <h2 style={{ margin: "0px" }}>Promotions</h2>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: "2em",
          }}
        >
          <Paper elevation={2} style={promoCardStyle}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h3>Search</h3>
              <SearchRedeemPromotionForm
                control={searchPromoControl}
                handleSubmit={handleSearchPromoSubmit}
                action={"search"}
                promotionDetails={searchPromotionDetails}
                setPromotionDetails={setSearchPromotionDetails}
                handleAction={handleValidate}
              />
            </div>
          </Paper>

          <Paper elevation={2} style={promoCardStyle}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h3>Redeem</h3>
              <SearchRedeemPromotionForm
                control={redeemPromoControl}
                handleSubmit={handleRedeemPromoSubmit}
                action={"redeem"}
                promotionDetails={redeemPromotionDetails}
                setPromotionDetails={setRedeemPromotionDetails}
                handleAction={handleRedeem}
              />
            </div>
          </Paper>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
        }}
      >
        {searchPromotionResult && (
          <div>
            <h3>Found Promotion</h3>
            <p>
              Discount:{" "}
              {formatDiscount(
                searchPromotionResult?.promoType,
                searchPromotionResult?.discount
              )}
            </p>
            <p>Expired: {searchPromotionResult?.expired ? "Yes" : "No"}</p>
            <p>Code: {searchPromotionResult?.code}</p>

            {searchPromotionDetails?.business &&
              searchPromotionResult?.campaignId && (
                <Button
                  variant="outlined"
                  style={{ width: "100%", textAlign: "left" }}
                  onClick={() =>
                    navigate(
                      `/business/${searchPromotionDetails?.business}/campaign/${searchPromotionResult.campaignId}`
                    )
                  }
                >
                  Go to campaign
                </Button>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Promotions;
