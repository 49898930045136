import React, { useState } from "react";
import {
  AppBar,
  Button,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import logo from "../images/ls-logo-png.png";
import useIsMobile from "../hooks/useIsMobile";

const NavBar = (props) => {
  const { user, signOut, role } = props;
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path) => {
    handleClose();
    navigate(path);
  };

  const logoButtonStyle = {
    color: "white",
    fontSize: "22px",
    textTransform: "none",
    fontWeight: "bold",
  };

  const logoStyle = {
    height: "50px",
    width: "50px",
    marginRight: "10px",
    position: "relative",
    backgroundPosition: "center center",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${logo})`,
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Button style={logoButtonStyle} onClick={() => navigate("/")}>
          <div style={logoStyle} />
        </Button>

        {isMobile ? (
          <Box sx={{ flexGrow: 1 }} />
        ) : (
          <>
            <Button
              style={{ color: "white", marginRight: "10px" }}
              onClick={() => handleNavigate("/")}
            >
              Home
            </Button>
            {role === "admin" && (
              <Button
                style={{ color: "white", marginRight: "10px" }}
                onClick={() => handleNavigate("/businesses")}
              >
                Businesses
              </Button>
            )}
            <Button
              style={{ color: "white", marginRight: "10px" }}
              onClick={() => handleNavigate("/promotions")}
            >
              Promotions
            </Button>
            {role === "admin" && (
              <Button
                style={{ color: "white", marginRight: "10px" }}
                onClick={() => handleNavigate("/playground")}
              >
                Playground
              </Button>
            )}
          </>
        )}

        <Box sx={{ flexGrow: 1 }} />

        {isMobile ? (
          <>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleNavigate("/")}>Home</MenuItem>
              {role === "admin" && (
                <MenuItem onClick={() => handleNavigate("/businesses")}>
                  Businesses
                </MenuItem>
              )}
              <MenuItem onClick={() => handleNavigate("/promotions")}>
                Promotions
              </MenuItem>
              {role === "admin" && (
                <MenuItem onClick={() => handleNavigate("/playground")}>
                  Playground
                </MenuItem>
              )}
              <MenuItem onClick={signOut}>Sign out</MenuItem>
            </Menu>
          </>
        ) : (
          <Button
            style={{ color: "white", borderColor: "white" }}
            onClick={signOut}
            variant="outlined"
          >
            Sign out
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
