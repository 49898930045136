import { Email, FirstName, LastName } from "./Form";
import { Button } from "@mui/material";

const CreateUserForm = (props) => {
  const { control, handleSubmit, user, setUser, handleCreateUser } = props;

  return (
    <div>
      <FirstName
        firstName={user?.firstName}
        setFirstName={(firstName) => {
          setUser({
            ...user,
            firstName: firstName,
          });
        }}
        control={control}
        name="firstName"
      />
      <LastName
        lastName={user?.lastName}
        setLastName={(lastName) => {
          setUser({
            ...user,
            lastName: lastName,
          });
        }}
        control={control}
        name="lastName"
      />
      <Email
        control={control}
        email={user?.email}
        setEmail={(email) => {
          setUser({ ...user, email: email });
        }}
      />

      <Button variant={"outlined"} onClick={handleSubmit(handleCreateUser)}>
        Create User
      </Button>
    </div>
  );
};

export default CreateUserForm;
