import { Controller, useForm } from "react-hook-form";
import { PhoneNumber } from "./Form";
import { Button, TextField } from "@mui/material";

import React, { useState, useEffect } from "react";

import PhoneOrMessagingServiceDropdownComponent from "./PhoneOrMessagingServiceDropdown";
import FileDropzone from "../FileDropzone";
import ImagePreview from "./ImagePreview";
import PromoCodeForm from "./PromoCodeForm";
import { UNSUBSCRIBE_MESSAGE } from "../../config/constants";
import useIsMobile from "../../hooks/useIsMobile";

const CampaignBlastForm = (props) => {
  const isMobile = useIsMobile();

  const { control, campaign, setCampaign } = props;
  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const role = sessionStorage.getItem("role");

  useEffect(() => {
    if (campaign?.messageImage && !imageFile) {
      setImageFile(campaign?.messageImage);
    }
  }, [campaign?.messageImage]);

  useEffect(() => {
    if (
      (campaign?.editableText && !campaign?.promotion) ||
      !campaign?.promotion ||
      Object.keys(campaign?.promotion)?.size == 0
    ) {
      setCampaign({
        ...campaign,
        messageBody: campaign?.editableText + UNSUBSCRIBE_MESSAGE,
      });
    } else if (campaign?.editableText && campaign?.editableText.trim() != "") {
      setCampaign({
        ...campaign,
        messageBody: formatMessageBody() + UNSUBSCRIBE_MESSAGE,
      });
    } else {
      setCampaign({ ...campaign, messageBody: "" });
    }
  }, [campaign?.editableText, campaign?.promotion]);

  const handleFileUpload = (files) => {
    setLoading(true);
    console.log("Received files:", files);

    const rawFile = files[0];

    const { name, size } = rawFile;

    const reader = new FileReader();
    reader.readAsDataURL(rawFile);
    reader.onload = () => {
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      setCampaign((prev) => ({
        ...prev,
        base64MessageImage: base64String,
        messageFileName: name,
        messageFileSize: size,
        messageImage: rawFile,
      }));

      setImageFile(URL.createObjectURL(rawFile));
    };

    reader.onerror = (error) => {
      console.log("Error reading file:", error);
      setLoading(false);
    };
  };

  const removeImage = () => {
    setCampaign((prev) => ({
      ...prev,
      base64MessageImage: null,
      messageImage: null,
    }));

    setImageFile(null);
  };

  const formatMessageBody = () => {
    if (!campaign?.promotion) return "";

    const { promoType, discount, expiration, code } = campaign?.promotion;

    let formattedDiscount = discount;

    if (promoType === "dollar") {
      formattedDiscount = `$${discount} off`;
    } else if (promoType === "percent") {
      formattedDiscount = `${discount}% off`;
    }

    // Check if expiration date is present and format it
    let expirationPart = "";
    if (expiration) {
      const expirationDate = new Date(expiration).toLocaleDateString();
      expirationPart = ` This offer is valid until ${expirationDate}.`;
    }

    let promotionMessage = `\n\nUse code '${code}' for ${formattedDiscount}!${expirationPart} *While supplies last*`;

    let formattedMessage = campaign?.editableText + promotionMessage;

    return formattedMessage;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: "2em",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          width: isMobile ? "100%" : "50%",
          flexDirection: "column",
          gap: "1em",
        }}
      >
        <Controller
          control={control}
          name={"campaignName"}
          rules={{
            required: {
              value: true,
              message: "You must enter a campaign name",
            },
          }}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <TextField
              disabled={role != "admin"}
              id="filled-multiline-static"
              label="Campaign Name"
              fullWidth
              required={true}
              value={campaign?.campaignName}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                const curr = e.target.value;
                onChange(curr);
                setCampaign({ ...campaign, campaignName: curr });
              }}
              error={error !== undefined}
              helperText={error ? error.message : ""}
            />
          )}
        />
        <PhoneOrMessagingServiceDropdownComponent
          campaign={campaign}
          setSelectedNumber={(num) => {
            setCampaign({ ...campaign, fromNumber: num });
          }}
          setSelectedService={(serv) => {
            setCampaign({ ...campaign, messagingServiceSID: serv });
          }}
        />

        <FileDropzone
          onFileUpload={handleFileUpload}
          fileName={campaign?.messageFileName}
          fileSize={campaign?.messageFileSize}
          acceptedFileTypes={{
            "image/jpeg": [".jpg", ".jpeg"],
            "image/png": [".png"],
          }}
          dropzoneMessage="Drag and drop an image file here, or click to select files"
        />

        <ImagePreview imageFile={imageFile} onRemove={removeImage} />

        <Controller
          control={control}
          name={"messageBody"}
          rules={{
            required: {
              value: true,
              message: "You must enter a message body",
            },
          }}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <TextField
              disabled={role != "admin"}
              id="filled-multiline-static"
              label="Body"
              multiline
              fullWidth
              rows={4}
              required={true}
              value={campaign?.editableText}
              variant="filled"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                let curr = e.target.value;

                onChange(curr);
                setCampaign({
                  ...campaign,
                  editableText: curr,
                });
              }}
              error={error !== undefined}
              helperText={error ? error.message : ""}
            />
          )}
        />
      </div>
      <div
        style={{
          display: "flex",
          width: isMobile ? "100%" : "50%",
          flexDirection: "column",
          gap: "1em",
        }}
      >
        <PromoCodeForm campaign={campaign} setCampaign={setCampaign} />
      </div>
    </div>
  );
};

export default CampaignBlastForm;
