import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const FileDropzone = ({
  onFileUpload,
  acceptedFileTypes,
  dropzoneMessage,
  fileName,
  fileSize,
}) => {
  const isAdmin = sessionStorage.getItem("role") === "admin";
  const [isHovered, setIsHovered] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (isAdmin) {
        onFileUpload(acceptedFiles);
      }
    },
    [onFileUpload, isAdmin] // Add isAdmin to the dependencies array
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes,
  });

  const toggleHover = (isHovering) => {
    setIsHovered(isHovering);
  };

  return (
    <div
      {...(isAdmin ? getRootProps() : {})} // Spread getRootProps only if isAdmin
      style={{
        border: "2px dashed gray",
        padding: "20px",
        textAlign: "center",
        cursor: isAdmin && isHovered ? "pointer" : "not-allowed",
        backgroundColor: isAdmin && isHovered ? "#e3f2fd" : "#f5f5f5",
        transition: "background-color 0.3s ease-in-out",
        opacity: isAdmin ? 1 : 0.5,
      }}
      onMouseEnter={() => toggleHover(true)}
      onMouseLeave={() => toggleHover(false)}
    >
      {isAdmin && <input {...getInputProps()} />}

      <p>{dropzoneMessage}</p>
      {fileName && fileSize && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <InsertDriveFileIcon />
          <span>
            {fileName} - {fileSize} bytes
          </span>
        </div>
      )}
    </div>
  );
};

export default FileDropzone;
