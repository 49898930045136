import { configureStore } from "@reduxjs/toolkit";
import businessReducer from "./business/businessSlice";
import campaignReducer from "./campaign/campaignSlice";
import playgroundReducer from "./playground/playgroundSlice";
import promotionsReducer from "./promotions/promotionsSlice";
import userReducer from "./user/userSlice";

export const store = configureStore({
  reducer: {
    business: businessReducer,
    user: userReducer,
    campaign: campaignReducer,
    playground: playgroundReducer,
    promotions: promotionsReducer,
  },
});
