import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { selectIsLoading } from "../models/selectors/loadingSelector";

const LoadingOverlay = () => {
  const isLoading = useSelector(selectIsLoading);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    let timer;

    if (isLoading) {
      setShowLoading(true);
    } else if (showLoading && !isLoading) {
      timer = setTimeout(() => setShowLoading(false), 350);
    }

    return () => clearTimeout(timer);
  }, [isLoading, showLoading]);

  return (
    <Backdrop
      open={showLoading}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingOverlay;
