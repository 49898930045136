import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const selectError = (state) => {
  return (
    state.campaign.error ||
    state.business.error ||
    state.playground.error ||
    state.promotions.error ||
    state.user.error
  );
};

const ErrorToast = () => {
  const error = useSelector(selectError);

  useEffect(() => {
    if (error) {
      console.log("error: ", error);
      toast.error(error);
    }
  }, [error]);

  return <ToastContainer />;
};

export default ErrorToast;
