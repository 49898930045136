import axios from "axios";
import { ROOT_URL } from "../config/constants";

// Create an Axios instance with a base URL
const axiosInstance = axios.create({
  baseURL: ROOT_URL,
});

// Interceptor for handling responses
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code within the range of 2xx will trigger this function
    return response;
  },
  (error) => {
    // Any status code outside the range of 2xx will trigger this function
    console.error("Axios response error:", error);

    // Check if the error response object exists
    if (error.response) {
      // You can handle specific status codes if needed
      // if (error.response.status === 404) {
      //   // Specific logic for 404 errors
      // }

      // Reject promise with the error message or custom error object
      return Promise.reject(error.response.data || error.response.statusText);
    } else if (error.request) {
      // The request was made but no response was received
      console.error("Axios request error:", error.request);
      return Promise.reject("No response received");
    } else {
      // Something happened in setting up the request that triggered an error
      console.error("Axios general error:", error.message);
      return Promise.reject(error.message);
    }
  }
);

export default axiosInstance;
