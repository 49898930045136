import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axiosConfig";

export const sendMessage = createAsyncThunk(
  "playground/sendMessage",
  async (messageDetails) => {
    const response = await axiosInstance.post(`sendMessage`, messageDetails);
    return response.data;
  }
);

const playgroundSlice = createSlice({
  name: "playground",
  initialState: {
    sentMessage: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.sentMessage = action.payload;
      })
      .addMatcher(
        (action) =>
          action.type.startsWith("playground/") &&
          action.type.endsWith("/pending"),
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        (action) =>
          action.type.startsWith("playground/") &&
          action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

export default playgroundSlice.reducer;
