import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import axios from "axios";
import { ROOT_URL } from "../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import { getBusinesses } from "../../models/business/businessSlice";

const BusinessSelect = (props) => {
  const isAdmin = sessionStorage.getItem("role") === "admin";

  const { control, business, setBusiness, name } = props;

  const businessSlice = useSelector((state) => state.business);

  const { businesses } = businessSlice;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBusinesses());
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel id="business-select-label">Business</InputLabel>
      <Controller
        name={name ? name : "business"}
        control={control}
        defaultValue=""
        rules={{
          required: {
            value: true,
            message: "You must select a business",
          },
        }}
        render={({ field }) => (
          <Select
            disabled={!isAdmin}
            {...field}
            labelId="business-select-label"
            label="Business"
            value={business}
            onChange={(e) => {
              setBusiness(e.target.value);
              field.onChange(e);
            }}
          >
            {businesses?.map((business) => (
              <MenuItem key={business.businessId} value={business.businessId}>
                {business.businessName}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default BusinessSelect;
