import React, { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";

const ImagePreview = ({ imageFile, onRemove }) => {
  if (!imageFile) {
    return null;
  }
  const isAdmin = sessionStorage.getItem("role") === "admin";

  console.log("image file: ", imageFile);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <img
        src={imageFile}
        alt="Preview"
        style={{ width: "150px", height: "auto" }}
      />
      <Button
        disabled={!isAdmin}
        variant="outlined"
        onClick={onRemove}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          cursor: "pointer",
        }}
      >
        Remove
      </Button>
    </div>
  );
};

export default ImagePreview;
