import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../axiosConfig";

export const createUser = createAsyncThunk(
  "user/createUser",
  async ({ businessId, user }) => {
    console.log("businessId: ", businessId);
    console.log("user: ", user);

    const response = await axiosInstance.post(
      `business/${businessId}/user`,
      user
    );
    return response.data;
  }
);

export const getUsers = createAsyncThunk(
  "user/getUsers",
  async (businessId) => {
    const response = await axiosInstance.get(`business/${businessId}/user`);
    return response.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    users: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addMatcher(
        (action) =>
          action.type.startsWith("user/") && action.type.endsWith("/pending"),
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addMatcher(
        (action) =>
          action.type.startsWith("user/") && action.type.endsWith("/rejected"),
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
  },
});

export default userSlice.reducer;
