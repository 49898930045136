import { Button, ButtonBase, Paper } from "@mui/material";

import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBusiness, getBusinesses } from "../models/business/businessSlice";

const UserHome = (props) => {
  const { user, businessId } = props;
  const isAdmin = sessionStorage.getItem("role") === "admin";

  const businessSlice = useSelector((state) => state.business);

  const { business } = businessSlice;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAdmin && businessId) {
      dispatch(getBusiness(businessId));
    }
  }, [businessId]);

  console.log("user: ", user);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "2em",
      }}
    >
      <div style={{ marginTop: "2em", display: "flex", flexDirection: "row" }}>
        <ButtonBase
          style={{ width: "100%", textAlign: "left" }}
          onClick={() => navigate(`/business/${business?.businessId}`)}
        >
          <Paper
            style={{
              margin: "10px",
              padding: "10px",
              boxShadow: "0px 3px 5px rgba(0,0,0,0.2)",
              width: "300px",
              height: "100px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <h3 style={{ margin: "0", fontWeight: "bold" }}>
                {business?.businessName}
              </h3>
              <p style={{ margin: "0" }}>{business?.phoneNumber}</p>
            </div>
          </Paper>
        </ButtonBase>
      </div>
    </div>
  );
};

export default UserHome;
