import { Button } from "@mui/material";

import { useState } from "react";
import { useForm } from "react-hook-form";

import CampaignComponent from "../components/CampaignComponent";
import { unwrapResult } from "@reduxjs/toolkit";

import { useDispatch } from "react-redux";
import { createCampaign } from "../models/campaign/campaignSlice";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useIsMobile from "../hooks/useIsMobile";

const defaultCampaign = {
  fromNumber: null,
  messagingServiceSID: null,
  messageBody: null,
  campaignName: null,
};

const Campaigns = (props) => {
  const { businessId } = useParams();
  const isMobile = useIsMobile();

  const [campaign, setCampaign] = useState(defaultCampaign);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      campaignName: campaign?.campaignName || "",
    },
  });

  const navigate = useNavigate();

  const navigateBack = () => navigate(-1);
  const dispatch = useDispatch();

  const handleCreateCampaign = () => {
    dispatch(
      createCampaign({
        businessId: businessId,
        campaign: {
          ...campaign,
          promotion: {
            ...campaign?.promotion,
            discount: parseFloat(campaign?.promotion?.discount),
          },
        },
      })
    )
      .then(unwrapResult)
      .then(() => {
        toast.success("Campaign successfully created");
      })
      .catch(() => {});
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <h1>Create Campaign</h1>

      <CampaignComponent
        campaign={campaign}
        setCampaign={setCampaign}
        control={control}
      />
      <Button onClick={handleSubmit(handleCreateCampaign)} variant="outlined">
        Create Campaignn
      </Button>
    </div>
  );
};

export default Campaigns;
