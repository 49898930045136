import { useForm } from "react-hook-form";
import SendTestMessageForm from "../components/Form/SendTestMessageForm";
import { useState } from "react";

import MessagePreview from "../components/MessagePreview";
import { useDispatch } from "react-redux";
import { sendMessage } from "../models/playground/playgroundSlice";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

const defaultMessageDetails = {
  toNumber: null,
  messageService: null,
  fromNumber: null,
  messageBody: null,
};

const Playground = () => {
  const [messageDetails, setMessageDetails] = useState(defaultMessageDetails);
  const { control, handleSubmit, setValue } = useForm();

  const dispatch = useDispatch();

  const handleSend = () => {
    dispatch(sendMessage(messageDetails))
      .then(unwrapResult)
      .then(() => {
        toast.success("Message successfully sent");
      })
      .catch(() => {});
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        columnGap: "2em",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SendTestMessageForm
        messageDetails={messageDetails}
        setMessageDetails={setMessageDetails}
        control={control}
        handleSendBlast={handleSend}
        handleSubmit={handleSubmit}
        setValue={setValue}
      />
      <MessagePreview
        imageFile={messageDetails?.imageFile}
        message={messageDetails?.messageBody}
      />
    </div>
  );
};

export default Playground;
