import { Button, ButtonBase, Paper } from "@mui/material";

import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBusiness, getBusinesses } from "../models/business/businessSlice";
import useIsMobile from "../hooks/useIsMobile";

const AdminHome = (props) => {
  const isMobile = useIsMobile();

  const { user } = props;
  const isAdmin = sessionStorage.getItem("role") === "admin";
  const userBusinessId = sessionStorage.getItem("businessId");

  const businessSlice = useSelector((state) => state.business);

  const { businesses } = businessSlice;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinesses());
  }, []);

  console.log("user: ", user);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "2em",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
        <h2>Businesses</h2>

        <Button variant="outlined" onClick={() => navigate(`/businesses`)}>
          Add new business
        </Button>
      </div>

      <div
        style={{
          marginTop: "2em",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {businesses?.map((biz) => {
          return (
            <ButtonBase
              style={{ width: "100%", textAlign: "left" }}
              onClick={() => navigate(`/business/${biz.businessId}`)}
            >
              <Paper
                style={{
                  margin: "10px",
                  padding: "10px",
                  boxShadow: "0px 3px 5px rgba(0,0,0,0.2)",
                  width: "300px",
                  height: "100px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <div>
                  <h3 style={{ margin: "0", fontWeight: "bold" }}>
                    {biz.businessName}
                  </h3>
                  <p style={{ margin: "0" }}>{biz.phoneNumber}</p>
                </div>
              </Paper>
            </ButtonBase>
          );
        })}
      </div>
    </div>
  );
};

export default AdminHome;
