import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  typography: {
    fontFamily: "Ubuntu, sans-serif",
  },
  palette: {
    mode: "light",
    primary: {
      main: "#121212", // A nice shade of blue
    },
    secondary: {
      main: "#f48fb1", // A complementary purple
    },
    background: {
      default: "#fff", // White background
      paper: "#f5f5f5", // Light grey for paper elements
    },
  },
});

const darkTheme = createTheme({
  typography: {
    fontFamily: "Ubuntu, sans-serif",
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9", // A softer blue for dark mode
    },
    secondary: {
      main: "#f48fb1", // A soft pink for contrast
    },
    background: {
      default: "#121212", // Standard dark mode background
      paper: "#1e1e1e", // Slightly lighter for paper elements
    },
  },
});

export { lightTheme, darkTheme };
